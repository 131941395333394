
import { LayoutComponent } from './layouts/layout/layout.component';
import { AuthService } from './core/service/auth.service';
import { Usuario } from './core/models/usuario.model';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';


@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet,LayoutComponent],
  providers: [AuthService]
})
export class AppComponent {

  usuario: Usuario;

  constructor(private authService: AuthService){
    this.authService.login();
    this.authService.getUser().subscribe((usuario) => {
      this.usuario = usuario;

       }, (error) =>{
    console.error(error);
  });

  }


}

